import { configureStore } from '@reduxjs/toolkit';

//import logger from 'redux-logger'
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import config from '../config';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),
  devTools: process.env.NODE_ENV !== 'production',
  initialState: config,
});

export default store;
