import React from 'react';
import './404.scss';

const NotFoundPage = () => {
  return (
    <div className="pageNotFound">
      <h1>OOPS! This page can't be found.</h1>
      <a href="/">Let's get you back home.</a>
    </div>
  );
};

export default NotFoundPage;
