import React, { Component } from "react";
import FontAwesome from "./FontAwesome";
import "./common.scss";

class Spinner extends Component {
  render() {
    return (
      <div className="Spinner">
        <FontAwesome type="spinner fa-pulse fa-2x fa-fw" />
        <FontAwesome type="sr-only" content="Loading..." />
      </div>
    );
  }
}

export default Spinner;
