import React from 'react';

function OfferSubCopy() {
    function GetPromotionText() {
        var today = new Date();
        var month = today.getMonth() + 1; // Month is zero-indexed, so we add 1
        var day = today.getDate();
        var year = today.getFullYear();
        // console.log(month)
        if (month === 5 && day >= 23 && day <= 31) {
          return ""; // Empty string for 5/23-5/31
        } else if (month === 6 && day >= 8 && day <= 15) {
          return "16 oz BOGO through 6/15/2023. Quantity Limits per Household Apply.";
        } else if (month === 6 && day >= 22 && day <= 30) {
          return "32 oz BOGO through 7/12/2023. Quantity Limits per Household Apply.";
        } else if (month === 7 && day >= 1 && day <= 12) {
          return "32 oz BOGO through 7/12/2023. Quantity Limits per Household Apply.";
        } else {
          return ""; // Return an empty string for any other date
        }
      }
  
  //list recipe sections
  return (<>
        <GetPromotionText/>
        </>
  );
}
export default OfferSubCopy;
