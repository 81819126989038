export const content = {
  hashTag: '#ItsThePerfectMatch',
  posts: [
    {
      title: 'Game Day Charcuterie Board',
      url: 'https://growingupbilingual.com/soccer-snack-board/',
      image: 'image--001.jpg',
    },
    {
      title: 'How to Plan a "Goal Worthy" Soccer Themed Party',
      url:
        'https://www.southernmadesimple.com/how-to-plan-a-goal-worthy-soccer-themed-party/',
      image: 'image--002.jpg',
    },
    {
      title: 'Enjoy Futbol Games with Pepsi',
      url: 'https://www.instagram.com/p/CNaKPZtLtkO/',
      image: 'image--003.jpg',
    },
  ],
};
