import React from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';
 
function Messenger() {
  const pageId = process.env.REACT_APP_CHATBOT_PAGEID;
  const appId = process.env.REACT_APP_CHATBOT_APPID;
  const htmlref = process.env.REACT_APP_CHATBOT_REF;
  return (
    <div>
      <MessengerCustomerChat
        pageId={pageId}
        appId={appId}
        htmlRef={htmlref}
      />
    </div>
  )
}
export default Messenger;