import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  state: 0,
  //username: "17831451227",
  username: undefined,
  myIds: ""
};
const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    INIT_USER: (state) => {
      return Object.assign({}, state, { state: 0 });
    },
    SET_USER: (state, action) => {
      const { username, myIds } = action.payload
      return Object.assign({}, state, { state: action.payload.state, username: username, myIds: myIds });
    },
    CLEAR_USER: (state, action) => {
      localStorage.removeItem("pbyt");
      return initialState;
    },
  },
});

export const { SET_USER, INIT_USER, CLEAR_USER } = slice.actions;

export default slice.reducer;
