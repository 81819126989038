import React from 'react';

import './footer.scss';

function Footer() {
  const brand = process.env.REACT_APP_BRAND_NAME;
  let path = '/images/' + brand + '/publix_exclusive-logo.png';

  return (
    <div className="footer-content" style={{ backgroundColor: process.env.REACT_APP_BG_COLOR }}>
      <img
        className={`footer-Img`}
        src={path}
        alt={process.env.REACT_APP_FOOTER_ALT}
      /> 
      <p className={`footer-text`}></p>
    </div>
  );
}
export default Footer;
