import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CLIP_ERROR } from "../redux/reducers/offer";
import Toast from "react-bootstrap/Toast";

function ErrorToast() {
  const dispatch = useDispatch();
  const clip_error = useSelector((state) => state.offer.errorMsg);
  let show = clip_error ? true : false;
  if (show) {
    setTimeout(function() {
      dispatch(CLIP_ERROR({ errorMsg: "" }));
    }, 3000);
    return (
      <Toast
        style={{
          position: "fixed",
          top: 0,
          right: 0,
        }}
      >
        <Toast.Header>
          <strong className="mr-auto">Error</strong>
        </Toast.Header>
        <Toast.Body>{clip_error}</Toast.Body>
      </Toast>
    );
  }

  return "";
}
export default ErrorToast;
