import React  from 'react';
import './image_row.scss';

function ImageRow() {
  const images = [
    '/images/'+process.env.REACT_APP_BRAND_NAME+'/tiles/tile_mild.jpg',
    '/images/'+process.env.REACT_APP_BRAND_NAME+'/tiles/tile_robust.jpg',
    '/images/'+process.env.REACT_APP_BRAND_NAME+'/tiles/tile_smooth.jpg',
    '/images/'+process.env.REACT_APP_BRAND_NAME+'/tiles/tile_bold.jpg',
  ];

  const sectionIds = ['Mild Taste', 'Robust', 'Smooth', 'Bold'];

  const handleClick = (sectionId) => {
    window.location.hash = sectionId;
  };

  return (
    
    <div className="image-row">
      {images.map((image, index) => (
        <div key={index} className="image-container">
          <img src={image} 
          alt={`Image ${index}`} 
          onClick={() => handleClick(sectionIds[index])}
          />
        </div>
      ))}
    </div>
  );
}

export default ImageRow;