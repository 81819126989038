import React from 'react';
import { Link } from "react-router-dom";
import FontAwesome from "../common/FontAwesome";

function RecipeContentItem(props) {
  const { title } = props.recipe;
  const {subheader} = props.recipe.recipeDetail;
  const { cardImg, a2c, short } = props.recipe.card;
  const cardImg_path = `/images/${process.env.REACT_APP_BRAND_NAME}/${cardImg}`;
  const linkTitle = title.replace(/ /g, '-');
  const cardText = short ? short : subheader;
  const sectionStyle = {
    backgroundImage: "url(" + cardImg_path + ")"
  };
  const buttonStyle = {
    backgroundColor: props.recipe.buttonBG,
  };
  function trackAddToCartEvent (){
    window.gtag('event', 'addtocartclick', { 
      product: title
     });
  }
  function trackGetRecipe (){
    window.gtag('event', 'viewrecipe', { 
      recipe: title
     });
  }

  //display recipe item card
  return (
    <div className="recipe-item">
    {
        <div>
          <div className='imageContent' style={ sectionStyle }>            
            {/* <img src={cardImg_path} className="card-img-top content-img" alt={title} />  */}
            { a2c.length > 0 &&
              <a 
                href={a2c} 
                onClick={trackAddToCartEvent} 
                target="_blank" 
                rel="noopener noreferrer"
                className='cart'
              >
                <FontAwesome type='shopping-cart' />
              </a>
            }

          </div>
          <div className='textContent'>
            <div className='button-items'>
              <button className="item-btn recipe-btn" style={buttonStyle}>
                <Link
                to={"/recipes/"+linkTitle}
                onClick={trackGetRecipe}
                >View Recipe</Link>
              </button>
              { a2c.length > 0 &&
                <button className="item-btn cart-btn" >
                  <a
                    href={a2c} 
                    onClick={trackAddToCartEvent} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >Add to Cart</a>
                </button>
              }
            </div>
            <div className='text-block'>
              <h4>{title}</h4>
              <p>{cardText}</p>
            </div>
          </div>
        </div>
        }
    </div>
  );
}
export default RecipeContentItem;
